export const labelForValueInLookup = (value, lookup) => {
  if (!Array.isArray(lookup)) return value
  const filtered = lookup.filter(item => item.value === value)
  if (filtered.length < 1) return value
  return filtered[0].label
}

export const replaceAllProperties = (object1, object2) => {
  Object.keys(object1).forEach(key => delete object1[key])
  Object.keys(object2).forEach(key => {object1[key] = object2[key]})
}

export const updateAllProperties = (object1, object2) => {
  Object.keys(object2).forEach(key => {object1[key] = object2[key]})
}

export const copyObject = (object) => {
  return JSON.parse(JSON.stringify(object))
}

export const parseJWT = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''))
  return JSON.parse(jsonPayload)
}

export const parseOrLogError = (string) => {
  try {
    return JSON.parse(string)
  } catch (e) {
    console.error(e)
  }
}

export const formatDate = (date) => {
  try {
    var appendGMT = ''
    const isDate = (date instanceof Date && !isNaN(date))
    if(isDate) {
      appendGMT = (date.toISOString().includes('Z') || date.toISOString().includes('GMT')) ? '' : 'Z'
    }
    else{
      appendGMT = (date.includes('Z') || date.includes('GMT')) ? '' : 'Z'
    }
    
    return new Intl.DateTimeFormat('en-AU', { dateStyle: 'short' }).format(new Date(date + appendGMT))
  } catch (e) {
    console.error(e)
    return date
  }
}

export const formatDateTime = (date) => {
  try {
    const appendGMT = date.includes('Z') ? '' : 'Z'
    return new Intl.DateTimeFormat('en-AU', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date + appendGMT))
  } catch (e) {
    console.error(e)
    return date
  }
}

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    }
  )
}

export const formatPhoneNumber = (input) => {
  let str = input.replace(/\s/g, '')
	if (str.length < 10) {
    return str
  }
  if (str.startsWith('13') || str.startsWith('18')) {
    return str.substring(0,4) + ' ' + str.substring(4,7) + ' ' + str.substring(7,str.length)
  }
  if (str.startsWith('+61')) {
    if (str[3] === '4') {
        return '(+61) ' + str.substring(3,6) + ' ' + str.substring(6,9) + ' ' + str.substring(9,str.length)
      } else {
        return '(+61) ' + str.substring(3,4) + ' ' + str.substring(4,8) + ' ' + str.substring(8,str.length)
      }
  }
  if (str.startsWith('04')) {
    return str.substring(0,4) + ' ' + str.substring(4,7) + ' ' + str.substring(7,str.length)
  }
  if (str.startsWith('614')) {
    return '0' + str.substring(2,5) + ' ' + str.substring(5,8) + ' ' + str.substring(8,str.length)
  }
  return str.substring(0,2) + ' ' + str.substring(2,6) + ' ' + str.substring(6,str.length) 
}

export const capitalize = (str) => {
  if(str.length == 0) return str
  return str[0].toUpperCase() + str.substr(1)
}


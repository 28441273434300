import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/state/modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',
  modules: {
    ...modules
  },
})

store.logout = async () => {
  console.log('store.logout')
  for (const key in modules) {
    const action = key + '/logout'
    const actionExists = Object.keys(store._actions).findIndex(key => key === action) !== -1
    if (actionExists) {
      await store.dispatch(action)
    }
  }
}

export default store

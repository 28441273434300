import authAPI from '@/api/auth'
import { parseJWT, parseOrLogError } from '@/utils/utils'

const state = {
  token: localStorage.getItem('token') || sessionStorage.getItem('token') || null,
  tokenPayload: parseOrLogError(localStorage.getItem('tokenPayload')) || parseOrLogError(sessionStorage.getItem('tokenPayload')) || null,
  refreshToken: localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken') || null,
  refreshTokenExpiration: localStorage.getItem('refreshTokenExpiration') || sessionStorage.getItem('refreshTokenExpiration') || null,
  rememberMeOnThisDevice: localStorage.getItem('rememberMeOnThisDevice') || sessionStorage.getItem('rememberMeOnThisDevice') || null,
  user: parseOrLogError(localStorage.getItem('user')) || parseOrLogError(sessionStorage.getItem('user')) || null,
}

const getters = {
  isLoggedIn: (state) => {
    return !!(state.token && state.user)
  },
  userId: (state) => {
    return state.tokenPayload?.u_guid
  },
  initials: (state, getters) => {
    if (!getters.isLoggedIn) return null
    if (state.user?.userName) return state.user?.userName?.match(/\b\w/g).join('')
    return state.user?.email?.substring(0, 2) || ''
  },
  isAdminUser: (state) => {
    return state.user?.roles?.includes('ADMIN') || state.user?.roles?.includes('SUPER_ADMIN')
  },
  isSuperAdminUser: (state) => {
    return !!state.user?.roles?.includes('SUPER_ADMIN')
  },
  buyerProfileIds: (state) => {
    if (!state.tokenPayload?.u_buyer_profiles) return []
    return state.tokenPayload.u_buyer_profiles.split(',')
  },
  sellerProfileIds: (state) => {
  if (!state.tokenPayload?.u_seller_profiles) return []
    return state.tokenPayload.u_seller_profiles.split(',')
  },
}

const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
  },
  SET_TOKEN_PAYLOAD (state, tokenPayload) {
    state.tokenPayload = tokenPayload
  },
  SET_REFRESH_TOKEN (state, refreshToken) {
    state.refreshToken = refreshToken
  },
  SET_REFRESH_TOKEN_EXPIRATION (state, refreshTokenExpiration) {
    state.refreshTokenExpiration = refreshTokenExpiration
  },
  SET_REMEMBER_ME_ON_THIS_DEVICE (state, rememberMeOnThisDevice) {
    state.rememberMeOnThisDevice = rememberMeOnThisDevice
  },
  SET_USER (state, user) {
    state.user = user
  },
  LOGOUT (state) {
    state.token = null
    state.tokenPayload = null
    state.refreshToken = null
    state.refreshTokenExpiration = null
    state.rememberMeOnThisDevice = null
    state.user = null
  }
}

const actions = {
  async login ({ commit, state }, { email, password, rememberMeOnThisDevice }) {
    let result
    if (!email) {
      rememberMeOnThisDevice = state.rememberMeOnThisDevice
      result = await authAPI.refresh(state.refreshToken)      
    } else {
      result = await authAPI.signin(email, password)
    }
    const user = {
      email: result.email,
      userName: result.userName,
      roles: result.roles
    }
    const tokenPayload = parseJWT(result.token)

    let storage = rememberMeOnThisDevice ? localStorage : sessionStorage

    storage.setItem('token', result.token)
    storage.setItem('tokenPayload', JSON.stringify(tokenPayload))
    storage.setItem('refreshToken', result.refreshToken)
    storage.setItem('refreshTokenExpiration', result.refreshTokenExpiration)
    storage.setItem('rememberMeOnThisDevice', rememberMeOnThisDevice)
    storage.setItem('user', JSON.stringify(user))

    commit('SET_TOKEN', result.token)
    commit('SET_TOKEN_PAYLOAD', tokenPayload)
    commit('SET_REFRESH_TOKEN', result.refreshToken)
    commit('SET_REFRESH_TOKEN_EXPIRATION', result.refreshTokenExpiration)
    commit('SET_REMEMBER_ME_ON_THIS_DEVICE', rememberMeOnThisDevice)
    commit('SET_USER', user)
  },
  logout ({ commit }) {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenPayload')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('refreshTokenExpiration')
    localStorage.removeItem('rememberMeOnThisDevice')
    localStorage.removeItem('user')

    sessionStorage.removeItem('token')
    sessionStorage.removeItem('tokenPayload')
    sessionStorage.removeItem('refreshToken')
    sessionStorage.removeItem('refreshTokenExpiration')
    sessionStorage.removeItem('rememberMeOnThisDevice')
    sessionStorage.removeItem('user')

    commit('LOGOUT')
  },
  autoLogin ({ commit }) {

  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export default [
    {
      path: '/profiles',
      name: 'Profiles',
      meta: {
        requiresAuth: true
      },
      component: () => import('@/views/organisation/MyOrgs')
    },
    {
      path: '/watchlist',
      name: 'Watchlist',
      meta: {
        requiresAuth: true
      },
      component: () => import('@/views/wanted/MyGrainWanted')
    },
]
export default [
  {
    path: '/listing',
    redirect: to => {return '/search'},
    children: [
      {
        path: 'search',
        redirect: to => {return '/search'},
      },
    ],
  },
  {
    path: '/user/login',
    redirect: to => {return '/login'},
  },
  {
    path: '/contact-us',
    redirect: to => {return '/'},
  },
  {
    path: '/meet-the-team',
    redirect: to => {return '/about'},
  },
  {
    path: '/about-*',
    redirect: to => {return '/about'},
  },
  {
    path: '/privacy-policy.dt',
    redirect: to => {return '/privacy'},
  },
  {
    path: '/resources/pdf/Insurance_Policy.pdf',
    beforeEnter(to, from, next) {
      window.location.href = process.env.VUE_APP_SITE_URL + '/img/igrain/terms/insurance-policy.pdf'
    }
  },
]

/*
/listing/search
/listing/search/?sold=true
/contact-us
/meet-the-team
/user/login
/about-us-faqs
/about-us-board-memebers
/about-us-careers
/about-us-vision-values
*/
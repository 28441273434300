export default [
  {
    path: '/bids',
    name: 'Bids',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/bid/Bids')
  }
]

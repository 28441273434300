import axios from './axios'
import Organisation from '@/model/organisation'

export default {
  async create(organisation) {
    try {
      const result = await axios.post('/organisation/create', organisation)
      return Organisation.setClass(result.org)
    } catch (e) {
      this.handleABNExistsError(e)
    }
  },
  async update(organisation) {
    try {
      const result = await axios.put('/organisation/update', organisation)
      return Organisation.setClass(result.org)
    } catch (e) {
      this.handleABNExistsError(e)
    }
  },

  handleABNExistsError(e) {
    if (e?.errorList?.filter((item) => item.key === 'ABN_EXISTS')?.length > 0) {
      const email = e?.errorList?.filter(
        (item) => item.key === 'EXISTING_ORG_WITH_ABN_PRIMARY_EMAIL',
      )?.[0]?.message
      throw new Error(
        'This ABN has already been registered with igrain. Please contact the organisation primary at ' +
          email +
          ' to have them add you as a secondary user of the org.',
      )
    } else {
      throw e
    }
  },

  async verifyFeature(orgId, feature) {
    const params = {
      orgId: orgId,
      feature: feature,
    }
    return await axios.post('/organisation/verify-feature', params)
  },
  async addProfile(orgId, email, isPrimary) {
    const params = {
      orgId: orgId,
      email: email,
      isPrimary: isPrimary,
    }
    const result = await axios.post('/organisation/profile', params)
    return Organisation.setClass(result.org)
  },
  async updateProfile(orgId, email, isPrimary) {
    const params = {
      orgId: orgId,
      email: email,
      isPrimary: isPrimary,
    }
    const result = await axios.put('/organisation/profile', params)
    return Organisation.setClass(result.org)
  },
  async deleteProfile(orgId, email) {
    const params = {
      orgId: orgId,
      email: email,
    }
    const result = await axios.delete('/organisation/profile', {
      data: params,
    })
    return Organisation.setClass(result.org)
  },
  async getList(
    numberOfItems,
    page,
    searchTerm,
    accountManagerId,
    isBuyer = false,
    isSeller = false,
  ) {
    const params = {
      numberOfItems: numberOfItems,
      page: page,
    }
    if (!(isBuyer && isSeller) && !(!isBuyer && !isSeller)) {
      if (isBuyer) {
        params.isBuyer = true
      }
      if (isSeller) {
        params.isSeller = true
      }
    }
    if (searchTerm) {
      params.searchTerm = searchTerm
    }
    if (accountManagerId) {
      params.accountManagerId = accountManagerId
    }
    const result = await axios.post('/organisation/list', params)
    Organisation.setClasses(result.data)
    return result
  },
  async getOrganisation(id) {
    const result = await axios.get('/organisation/' + id)
    return Organisation.setClass(result)
  },
  async getUserProfileList() {
    return await axios.get('/organisation/userprofile-list')
  },
}

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login')
  },
  // {
  //   path: '/register',
  //   component: () => import('@/views/auth/Register'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'Form',
  //       component: () => import('@/views/auth/RegisterForm'),
  //     },
  //     {
  //       path: 'verify',
  //       name: 'Verify',
  //       props: true,
  //       component: () => import('@/views/auth/RegisterVerify'),
  //     }
  //   ]
  // },
  {
    path: '/auth/resetpassword',
    name: 'ResetPassword',
    component: () => import('@/views/auth/PasswordReset')
  },
]

import axios from './axios'

export default {
  async getLookups() {
    return await axios.get('/lookup', { unsecured: true })
  },

  async getGrain() {
    return await axios.get('/lookup/grain')
  },
  async addGrain(obj) {
    return await axios.post('/lookup/grain', obj)
  },
  async updateGrain(obj) {
    return await axios.put('/lookup/grain', obj)
  },

  async addGrade(obj) {
    return await axios.post('/lookup/grade', obj)
  },
  async updateGrade(obj) {
    return await axios.put('/lookup/grade', obj)
  },

  async getWarehouseLocation() {
    return await axios.get('/lookup/warehouse-location')
  },
  async addWarehouseLocation(obj) {
    return await axios.post('/lookup/warehouse-location', obj)
  },
  async updateWarehouseLocation(obj) {
    return await axios.put('/lookup/warehouse-location', obj)
  },

  async getFarmStorage() {
    return await axios.get('/lookup/farm-storage')
  },
  async addFarmStorage(obj) {
    return await axios.post('/lookup/farm-storage', obj)
  },
  async updateFarmStorage(obj) {
    return await axios.put('/lookup/farm-storage', obj)
  },

  async getFirmOfferExpiry() {
    return await axios.get('/lookup/firm-offer-expiry')
  },
  async addFirmOfferExpiry(obj) {
    return await axios.post('/lookup/firm-offer-expiry', obj)
  },
  async updateFirmOfferExpiry(obj) {
    return await axios.put('/lookup/firm-offer-expiry', obj)
  },

  async getPaymentTerm() {
    return await axios.get('/lookup/payment-term')
  },
  async addPaymentTerm(obj) {
    return await axios.post('/lookup/payment-term', obj)
  },
  async updatePaymentTerm(obj) {
    return await axios.put('/lookup/payment-term', obj)
  },

  async getSeason() {
    return await axios.get('/lookup/season')
  },
  async addSeason(obj) {
    return await axios.post('/lookup/season', obj)
  },
  async updateSeason(obj) {
    return await axios.put('/lookup/season', obj)
  },

  async getStates() {
    return await axios.get('/lookup/state')
  },
  async addStates(obj) {
    return await axios.post('/lookup/state', obj)
  },
  async updateStates(obj) {
    return await axios.put('/lookup/state', obj)
  }
}

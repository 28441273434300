import { formatDateTime, toTitleCase } from '@/utils/utils'

export default class Subscription {
  get level () {
    let level = 'BASIC'
    const plan = this.planName.toLowerCase()
    if (plan.includes('bronze')) {
      level = 'BRONZE'
    } else if (plan.includes('silver')) {
      level = 'SILVER'
    } else if (plan.includes('gold')) {
      level = 'GOLD'
    } else if (plan.includes('platinum')) {
      level = 'PLATINUM'
    }
    return level
  }
  get levelRank () {
    switch (this.level) {
      case 'BASIC':
        return 10
        break
      case 'BRONZE':
        return 20
        break
      case 'SILVER':
        return 30
        break
      case 'GOLD':
        return 40
        break   
      case 'PLATINUM':
        return 50
        break     
      default:
        return 0
        break
    }
  }
  get isActive () {
    return this.status === 'active'
  }
  get dateStartFormatted () {
    return formatDateTime(this.dateStart)
  }
  get dateEndFormatted () {
    return formatDateTime(this.dateEnd)
  }
  get statusFormatted () {
    return toTitleCase(this.statusFormatted)
  }

  static setClass (obj) {
    if (obj instanceof Object !== true) return
    Object.setPrototypeOf(obj, Subscription.prototype)
    return obj
  }
  static setClasses (objs) {
    objs.forEach(obj => Subscription.setClass(obj))
    return objs
  }
}

import store from '@/state/store'
import { parseOrLogError } from '@/utils/utils'

// Keep auth state in-sync with localstorage (if another tab / window logs in or out)
const authTokenListener = event => {
  console.debug('authTokenListener', event)

  // Log out
  if (event.key === 'token' && event.newValue === null) {
    console.debug('authTokenListener', 'logout')
    store.dispatch('auth/logout')
  } else {
    // Sync updates
    if (event.key === 'token' && event.newValue) store.commit('auth/SET_TOKEN', event.newValue)
    if (event.key === 'tokenPayload' && event.newValue) store.commit('auth/SET_TOKEN_PAYLOAD', parseOrLogError(event.newValue))
    if (event.key === 'refreshToken' && event.newValue) store.commit('auth/SET_REFRESH_TOKEN', event.newValue)
    if (event.key === 'refreshTokenExpiration' && event.newValue) store.commit('auth/SET_REFRESH_TOKEN_EXPIRATION', event.newValue)
    if (event.key === 'rememberMeOnThisDevice' && event.newValue) store.commit('auth/SET_REMEMBER_ME_ON_THIS_DEVICE', event.newValue)
    if (event.key === 'user' && event.newValue) store.commit('auth/SET_USER', parseOrLogError(event.newValue))
  }
}
window.addEventListener('storage', authTokenListener)

export default () => {
  console.log('token', 'test')
}
import {
  cilArrowCircleBottom,
  cilArrowCircleRight,
  cilAt,
  cilBarChart,
  cilBell,
  cilCheckAlt,
  cilCheckCircle,
  cilClone,
  cilCloudDownload,
  cilCommentSquare,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilExternalLink,
  cilFactory,
  cilFile,
  cilFlower,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilHome,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilLocomotive,
  cilMobile,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPlant,
  cilPlus,
  cilPuzzle,
  cilReload,
  cilSettings,
  cilShieldAlt,
  cilSitemap,
  cilSpa,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserPlus,
  cilVoiceOverRecord,
  cilX,
  cilXCircle,
  cibFacebook,
  cibTwitter,
  cibInstagram,
  cibLinkedin,
} from '@coreui/icons'

export const iconSet = Object.assign({
  cilArrowCircleBottom,
  cilArrowCircleRight,
  cilAt,
  cilBarChart,
  cilBell,
  cilCheckAlt,
  cilCheckCircle,
  cilClone,
  cilCloudDownload,
  cilCommentSquare,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilExternalLink,
  cilFactory,
  cilFile,
  cilFlower,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilHome,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilLocomotive,
  cilMobile,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPlant,
  cilPlus,
  cilPuzzle,
  cilReload,
  cilSettings,
  cilShieldAlt,
  cilSitemap,
  cilSpa,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserPlus,
  cilVoiceOverRecord,
  cilX,
  cilXCircle,

  cibFacebook,
  cibTwitter,
  cibInstagram,
  cibLinkedin,
})

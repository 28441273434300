import { labelForValueInLookup, formatDate, updateAllProperties, formatPhoneNumber } from '@/utils/utils'
import store from '@/state/store'
import Subscription from './subscription'

export default class Organisation {
  constructor (values) {
    updateAllProperties(this, values)
  }

  get value () {return this.guid}
  get label () {return this.tradingName}
  get regionLabel () {return labelForValueInLookup(this.address?.region, store.getters['lookup/regions'](this.address?.state)) || ''}
  get phoneNumberLabel () {return formatPhoneNumber(this.phoneNumber)}
  get dateCreatedDateOnly () {return formatDate(this.dateCreated)}
  get buyerPermission () {return this.permissions?.filter(item => item.feature === 'BUYER')?.[0]}
  get sellerPermission () {return this.permissions?.filter(item => item.feature === 'SELLER')?.[0]}
  get isBuyer () {return Boolean(this.buyerPermission)}
  get isSeller () {return Boolean(this.sellerPermission)}
  get buyerVerified () {return Boolean(this.buyerPermission?.isVerified)}
  get sellerVerified () {return Boolean(this.sellerPermission?.isVerified)}
  get primaryProfile () {return this.profiles.filter(item => item.isPrimary === true)?.[0]}
  get primaryProfileFullName () {
    const primaryProfile = this.primaryProfile
    return primaryProfile ? [primaryProfile.firstName, primaryProfile.lastName].join(' ') : ''
  }
  get profileCount () {return this.profiles.length}
  get hasSubscription () {return this.subscription != null}

  userIsPrimary (guid) {
    return this.primaryProfile?.guid == guid
  }

  static sanitiseModel (input, userId, isAdminUser, onBehalfOfUserId) {
    const org = JSON.parse(JSON.stringify(input))

      // ACN
      if (org.orgType !== 'COMPANY') delete org.acn

      // Set region on address
      org.address.region = org.region

      // Managed by
      if (onBehalfOfUserId) {
        org.managedByUserId = onBehalfOfUserId
        org.accountManagerUserId = userId
      } else if (isAdminUser) {
        org.managedByUserId = userId
      }

      // Feature permissions
      org.featurePermissions = []
      if (org.buying) org.featurePermissions.push('BUYER')
      if (org.selling) org.featurePermissions.push('SELLER')
      delete org.buying
      delete org.selling

    return org
  }

  static prepareUpdateModel (input) {
    const org = {
      orgId: input.guid,
      tradingName: input.tradingName,
      address: input.address,
      abn: input.abn,
      acn: input.acn,
      ngr: input.ngr,
      orgType: input.orgType,
      phoneNumber: input.phoneNumber,
      email: input.email,
      creditLimit: input.creditLimit,
      accountManagerUserId: input.accountManagerUserId || input.accountManager.guid,
      orgState: input.orgState,
    }

    org.address.region = input.region
    if (org.orgType !== 'COMPANY') delete org.acn
    org.featurePermissions = []
    if (input.buying) org.featurePermissions.push('BUYER')
    if (input.selling) org.featurePermissions.push('SELLER')

    console.log('prepareUpdateModel', input, org)

    return org
  }

  static setClass (obj) {
    if (obj instanceof Object !== true) return
    console.log('Organisation', 'setClass')

    // Move region from address to base
    if (!obj.region && obj.address?.region) {
      obj.region = obj.address.region
    }

    // Set formattedAddress
    if (obj.address && obj.address.streetAddress) {
      obj.address.formattedAddress = obj.address.streetAddress + ', ' + obj.address.city + ' ' + obj.address.state
    }

    // Permissions
    if (obj.buying === undefined) {
      obj.buying = Boolean(this.permissions?.filter(item => item.feature === 'BUYER')?.[0])
    }
    if (obj.selling === undefined) {
      obj.selling = Boolean(this.permissions?.filter(item => item.feature === 'SELLER')?.[0])
    } 

    // Account manager
    if (obj.accountManagerUserId === undefined) {
      obj.accountManagerUserId = obj.accountManager?.guid
    }

    Object.setPrototypeOf(obj, Organisation.prototype)
    Subscription.setClass(obj.subscription)
    return obj
  }
  static setClasses (objs) {
    objs.forEach(obj => Organisation.setClass(obj))
    return objs
  }
  static copy (obj) {
    const copy = JSON.parse(JSON.stringify(obj))
    Organisation.setClass(copy)
    return copy
  }
}
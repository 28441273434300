import { updateAllProperties } from '@/utils/utils'

export default class User {
  constructor (values) {
    updateAllProperties(this, values)
  }
  
  get fullName () {return (this.firstName || '').trim() + ' ' + (this.lastName || '').trim()}
  get phoneNumberFormatted () {
    try {
      return this.phoneNumber.substring(0,4) + ' ' + this.phoneNumber.substring(4,7) + ' ' + this.phoneNumber.substring(7,10)
    } catch (e) {
      return this.phoneNumber      
    }
  }

  get flatRoles () {return this.roles.map(item => item.name)}
  get isUser () {return this.hasRole('USER')}
  get isAdmin () {return this.hasRole('ADMIN')}
  get isSuperAdmin () {return this.hasRole('SUPER_ADMIN')}

  hasRole (role) {
    return this.flatRoles.includes(role)
  }

  static getNewUser() { 
    return new User({
      guid: null,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      roles: []
    })
  
  }

  static setClass (obj) {
    if (obj instanceof Object !== true) return
    Object.setPrototypeOf(obj, User.prototype)
    return obj
  }
  static setClasses (objs) {
    objs.forEach(obj => User.setClass(obj))
    return objs
  }
  static copy (obj) {
    const copy = JSON.parse(JSON.stringify(obj))
    User.setClass(copy)
    return copy
  }
}
import 'core-js/stable'
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconSet as icons } from '@/assets/icons/icons'
import store from './state/store'
import token from './state/token'
import GmapVue from 'gmap-vue'

token()

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.use(GmapVue, {
  load: { key: 'AIzaSyCmZssR_wE7EFy8i86PUlsU6s0N1xArlaE', customCallback: 'MyCustomCallback' },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})


import { labelForValueInLookup, formatDate, formatDateTime, updateAllProperties } from '@/utils/utils'
import store from '@/state/store'

export default class MyGrainWanted {
  constructor (values) {
    updateAllProperties(this, values)
  }

  get dateCreatedFormatted () {return formatDateTime(this.dateCreated)}
  get dateCreatedDateOnlyFormatted () {return formatDate(this.dateCreated)}
  get dateExpiryFormatted () {return formatDate(this.dateExpiry)}

  grainLabel (grain) {return labelForValueInLookup(grain, store.state.lookup.grain)}
  gradeLabel (grain, grade) {
    const gradeLabel = labelForValueInLookup(grade, store.getters['lookup/grades'](grain)) || ''
    const gradeParts = gradeLabel.split(' - ')
    return gradeParts.length > 1 ? gradeParts[1] : grade
  }

  get storageTypeLabel () {return labelForValueInLookup(this.storageType, store.state.lookup.storageType) || ''}
  get offerTypeLabel () {return labelForValueInLookup(this.offerType, store.state.lookup.offerType) || ''}
  get priceLabel () { return this.priceMax ? '$' + this.priceMax : '' }

  static setClass (obj) {
    if (obj instanceof Object !== true) return
    Object.setPrototypeOf(obj, MyGrainWanted.prototype)
    return obj
  }
  static setClasses (objs) {
    objs.forEach(obj => MyGrainWanted.setClass(obj))
    return objs
  }
  static copy (obj) {
    const copy = JSON.parse(JSON.stringify(obj))
    MyGrainWanted.setClass(copy)
    return copy
  }
}

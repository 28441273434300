const state = {
  routeLabel: '',
  sections: [
    {
      label: 'In flight',
      items: [
        {
          label: 'Bids & counters',
          to: '/bids',
          icon: 'cil-task'
        },
        {
          label: 'Your offers',
          to: '/offers',
          icon: 'cil-flower'
        }
      ]
    },
    {
      label: 'Account',
      items: [
        {
          label: 'Profiles',
          to: '/profiles',
          icon: 'cil-people',
        },
        {
          label: 'Watchlist / grain wanted',
          to: '/watchlist',
          icon: 'cil-star',
         }
        // {
        //   label: 'Account settings',
        //   icon: 'cil-settings',
        // },
      ]
    }
  ]
}

const getters = {}

const mutations = {
  SET_ROUTE_LABEL (state, routeLabel) {
    state.routeLabel = routeLabel
  },
}

const actions = {
  setRouteLabel ({ commit }, routeLabel) {
    commit('SET_ROUTE_LABEL', routeLabel)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
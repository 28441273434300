import axios from './axios'
import User from '@/model/user'
import MyGrainWanted from '@/model/my-grain-wanted'

export default {
  async getList (numberOfItems, page, searchTerm, abortSignal) {
    const params = {
      numberOfItems: numberOfItems,
      page: page
    }
    if (searchTerm) {
      params.searchTerm = searchTerm
    }
    const result = await axios.post('/user/list', params, {signal: abortSignal})
    User.setClasses(result.data)
    return result
  },
  async getUser (guid) {
    const result = await axios.get('/user/' + guid)
    User.setClass(result.user)
    return result.user
  },
  async updateUser (guid, firstName, lastName, email, phoneNumber) {
    const params = {
      guid: guid, 
      firstName: firstName, 
      lastName: lastName, 
      email: email, 
      phoneNumber: phoneNumber
    }
    const result = await axios.put('/user/', params)
    User.setClass(result)
    return result
  },

  async createUser (firstName, lastName, email, phoneNumber) {
    const params = {
      firstName: firstName, 
      lastName: lastName, 
      email: email, 
      phoneNumber: phoneNumber
    }
    const result = await axios.post('/user/', params)
    User.setClass(result)
    return result
  },

  async getGrainAlert (ownerId) {
    const result = await axios.get('/user/grain-alert/' + ownerId)
    MyGrainWanted.setClasses(result)
    return result
  },
  async getGrainWatch (ownerId) {
    const result =  await axios.get('/user/grain-watch/' + ownerId)
    MyGrainWanted.setClasses(result)
    return result
  },
  async deleteGrainSearch (searchId) {
    return await axios.delete('/user/grain-search/' + searchId)
  },
  async createGrainSearch (ownerId, searchType, dateExpiry, apiSearch, comment) {
    const params = apiSearch
    params.ownerId = ownerId
    params.searchType = searchType
    params.dateExpiry = dateExpiry
    params.comment = comment
    console.log('createGrainSearch', params)
    return await axios.post('/user/grain-search', params)
  },

  async addRole (email, role) { // USER, ADMIN, SUPER_ADMIN
    const params = {email: email, role: role}
    return await axios.post('/user/roles', params)
  },
  async removeRole (email, role) {
    const params = {email: email, role: role}
    return await axios.delete('/user/roles', {data: params})
  },
}
import organisationAPI from '@/api/organisation'
import userAPI from '@/api/user'
import store from '@/state/store'
import { updateAllProperties } from '@/utils/utils.js'

const state = {
  gotOrganisations: false,
  organisations: [],
  onBehalfOfUser: null
}

const getters = {
  organisation: (state) => (orgId) => {
    const filtered = state.organisations.filter(item => item.guid === orgId)
    return filtered.length > 0 ? filtered[0] : null
  },

  buyerOrgs: (state) => {
    return state.organisations.filter(item => item.isBuyer)
  },

  sellerOrgs: (state) => {
    return state.organisations.filter(item => item.isSeller)
  },

  isBuyer: (state, getters) => {
    return getters.buyerOrgs.length > 0
  },

  isSeller: (state, getters) => {
    console.log('isSeller', getters.sellerOrgs.length > 0)
    return getters.sellerOrgs.length > 0
  },

  hasVerifiedBuyerOrg: (state, getters) => {
    return getters.buyerOrgs.filter(item => item.buyerVerified).length > 0
  },

  hasVerifiedSellerOrg: (state, getters) => {
    return getters.buyerOrgs.filter(item =>item.sellerVerified).length > 0
  },

  hasOrg: (state) => {
    return state.organisations.length > 0
  },

  hasOrgWithPlus: (state) => {
    const filtered = state.organisations.filter(item => item.hasSubscription)
    return filtered.length > 0
  },

  orgsWithPlus: (state) => {
    return state.organisations.filter(item => item.hasSubscription)
  },

  hasOrgWithActivePlus: (state) => {
    const filtered = state.organisations.filter(item => item.hasSubscription && item.subscription.isActive)
    return filtered.length > 0
  },

  orgsWithActivePlus: (state) => {
    return state.organisations.filter(item => item.hasSubscription && item.subscription.isActive)
  },

  highestLevelActivePlusOrg: (state) => {
    const filtered = state.organisations.filter(item => item.hasSubscription && item.subscription.isActive)
    if (filtered.length > 0) {
      return filtered.sort((a, b) => b.subscription.levelRank - a.subscription.levelRank)[0]
    }
  },
  orgsSortedByPlusLevel: (state) => {
    return state.organisations.slice().sort((a, b) => (b.subscription?.levelRank || 0) - (a.subscription?.levelRank || 0))
  }
}

const mutations = {
  SET_GOT_ORGANISATIONS (state, gotOrganisations) {
    state.gotOrganisations = gotOrganisations
  },
  SET_ORGANISATIONS (state, organisations) {
    state.organisations = organisations
  },
  UPDATE_ORGANISATION (state, org) {
    const existingOrg = state.organisations.filter(item => item.guid === org.guid)[0]
    updateAllProperties(existingOrg, org)
  },
  SET_ON_BEHALF_OF_USER (state, onBehalfOfUser) {
    state.onBehalfOfUser = onBehalfOfUser
  },
}

const actions = {
  async getOrganisations ({ commit, state }, { force }) {
    if (state.gotOrganisations && !force) return true
    let uniqueOrgIds
    if (state.onBehalfOfUser) {
      const user = await userAPI.getUser(state.onBehalfOfUser.guid) 
      uniqueOrgIds = user.profiles.map(item => item.orgGuid)
    } else {
      const buyerOrgs = store.getters['auth/buyerProfileIds']
      const sellerOrgs = store.getters['auth/sellerProfileIds']
      const orgIds = [...buyerOrgs, ...sellerOrgs]
      uniqueOrgIds = orgIds.filter((v, i, a) => a.indexOf(v) === i)
    }
    const promises = uniqueOrgIds.map(item => organisationAPI.getOrganisation(item)) // Get each organisation from the API
    const results = await Promise.all(promises)
    results.sort((a, b) => b.dateCreated.localeCompare(a.dateCreated))

    commit('SET_GOT_ORGANISATIONS', true)
    commit('SET_ORGANISATIONS', results)
  },

  async setOnBehalfOfUser ({ commit, dispatch }, onBehalfOfUser) { 
    commit('SET_ON_BEHALF_OF_USER', onBehalfOfUser)
    await dispatch('getOrganisations', {force: true})
  },

  update ({ commit }, org) {
    commit('UPDATE_ORGANISATION', org)
  },

  async create ({ dispatch }, org) {
    const result = await organisationAPI.create(org)
    await store.dispatch('auth/login', {}) // Refresh the access token with new org permissions
    await dispatch('getOrganisations', {force: true})
    return result
  },

  async logout ({ commit }) {
    commit('SET_GOT_ORGANISATIONS', false)
    commit('SET_ORGANISATIONS', [])
    commit('SET_ON_BEHALF_OF_USER', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
const AdminContainer = () => import('@/views/admin/Container')
const Dashboard = () => import('@/views/admin/Dashboard')

const routes = {
  path: '/admin',
  redirect: '/admin/users',
  name: 'Admin',
  meta: { requiresAuth: true },
  component: AdminContainer,
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: 'users',
      name: 'Users',
      component: () => import('@/views/admin/Users'),
      children: [
        {
          path: ':userKey',
          name: 'User',
          props: true,
          meta: {
            isDynamic: true,
          },
          component: () => import('@/views/admin/UserForm'),
        },
      ],
    },
    {
      path: 'orgs',
      name: 'Organisations',
      component: () => import('@/views/admin/Organisations'),
      children: [
        {
          path: ':orgKey',
          name: 'Organisation',
          props: true,
          meta: {
            isDynamic: true,
          },
          component: () => import('@/views/admin/OrgWrapper'),
        },
      ],
    },
    {
      path: 'grain',
      name: 'Grain & grades',
      component: () => import('@/views/admin/Grains'),
      children: [
        {
          path: ':grainKey',
          name: 'Grain',
          props: true,
          meta: {
            isDynamic: true,
          },
          component: () => import('@/views/admin/Grain'),
        },
      ],
    },
    {
      path: 'account-managers',
      name: 'Account managers',
      component: () => import('@/views/admin/AccountManagers'),
    },
    {
      path: 'warehouse-location',
      name: 'Warehouse location',
      component: () => import('@/views/admin/WarehouseLocations'),
    },
    {
      path: 'on-farm-storage',
      name: 'On farm storage',
      props: { type: 'FarmStorage' },
      component: () => import('@/views/admin/LookupList'),
    },
    {
      path: 'firm-offer-expiry',
      name: 'Firm offer expiry',
      props: { type: 'FirmOfferExpiry' },
      component: () => import('@/views/admin/LookupList'),
    },
    {
      path: 'payment-term',
      name: 'Payment term',
      props: { type: 'PaymentTerm' },
      component: () => import('@/views/admin/LookupList'),
    },
    {
      path: 'season',
      name: 'Season',
      props: { type: 'Season' },
      component: () => import('@/views/admin/LookupList'),
    },
    {
      path: 'csv-report',
      name: 'CSV Reports',
      component: () => import('@/views/admin/reports/CsvReport'),
    }
   
  ],
}

export default routes

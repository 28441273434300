<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  async mounted () {
    await this.frontload(this.isLoggedIn)
  },
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn',
    ])
  },
  watch: {
    async 'isLoggedIn' (newValue) {
      this.frontload(newValue)
    }
  },
  methods: {
    ...mapActions('lookup', [
      'getLookups'
    ]),
    ...mapActions('organisation', [
      'getOrganisations'
    ]),
    async frontload (isLoggedIn) {
      console.log('App', 'watch', 'isLoggedIn', isLoggedIn)
      if (isLoggedIn) {
        await Promise.all([this.getLookups(), this.getOrganisations({force: false})])
      } else {
        await Promise.all([this.$store.logout(), this.getLookups()])
      }
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

import axios from './axios'

export default {
  async signin (email, password) {
    const params =  {
      email: email, 
      password: password
    }
    return await axios.post('/auth/signin', params, {unsecured: true})
  },

  async refresh (refreshToken) { 
    return await axios.post('/auth/refresh', {refreshToken: refreshToken}, {unsecured: true})
  },

  // async register (email, password, firstName, lastName, phoneNumber) {
  //   // const md5 = crypto.createHash('md5').update(password).digest('hex')
  //   const params = {
  //     email: email, 
  //     password: password,
  //     firstName: firstName,
  //     lastName: lastName,
  //     phoneNumber: phoneNumber
  //   }
  //   return await axios.post('/auth/register', params, {unsecured: true})
  // },

  // async verifyAccount (guid, otp) {
  //   return await axios.post('/auth/verifyaccount?guid=' + guid + '&otp=' + otp, null, {unsecured: true})
  // },

  async sendOTP (guid) {
    return await axios.post('/auth/sendotp?guid=' + guid, null, {unsecured: true})
  },

  async passwordResetLink (email) { 
    return await axios.post('/auth/passwordresetlink', {emailAddress: email}, {unsecured: true})
  },

  async resetPassword (newPassword, signature) { 
    return await axios.post('/auth/resetpassword', {newPassword: newPassword, signature: signature}, {unsecured: true})
  },

  async passwordResetLinkAdmin (email) { 
    return await axios.post('/auth/passwordresetlink-admin', {emailAddress: email})
  },
}
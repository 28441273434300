import { copyObject } from '@/utils/utils'

const defaultMarketSearch = {
  grain: 'WHEAT',
  grade: 'APW1_CSG103_WHEAT',
  site: localStorage.getItem('site') || 'PARKES_SUB_GRAINCORP',
  distance: 100,
  transactionType: 'ALL',
  dateRange: '90_DAYS'
}

const state = {
  marketSearch: copyObject(defaultMarketSearch)
}

const getters = {}

const mutations = {
  SET_MARKET_SEARCH (state, marketSearch) {
    state.marketSearch = marketSearch
  },
}

const actions = {
  setMarketSearchValue ({ commit, state }, {key, value}) {
    const marketSearch = copyObject(state.marketSearch)
    marketSearch[key] = value
    commit('SET_MARKET_SEARCH', marketSearch)

    // Persist site option
    if (key === 'site') {
      localStorage.setItem('site', value)
    }
  },

  async logout ({ commit }) {
    localStorage.removeItem('site')
    commit('SET_MARKET_SEARCH', defaultMarketSearch)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
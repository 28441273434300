const state = {
  adminSidebarShow: true,
  adminSidebarMinimize: false,
  sidebarShow: false
}

const getters = {}

const mutations = {
  toggleAdminSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.adminSidebarShow)
    state.adminSidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleAdminSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.adminSidebarShow)
    state.adminSidebarShow = sidebarClosed ? true : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

const actions = {}

export default {
  state,
  getters,
  actions,
  mutations
}